.profile-header {
    padding: 20px 0;

    box-sizing: border-box;
    border-bottom: 1px solid rgba(136, 136, 136, 0.4);
    border-radius: 0px 0px 10px 0px;
    background: rgb(255, 255, 255);
}

.text-h1 {
    color: rgb(0, 0, 0);
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0%;
    text-align: left;
}

.profile-header>.container {
    margin: 0 56px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-header ul {
    display: flex;
    align-items: center;
}

.profile-header li:not(:last-child) {
    margin-right: 55px;
}

.profile-header-nav {
    display: flex;
    align-items: center;
}

.profile-header-nav__item {
    position: relative;
}

.profile-header-nav__header {
    display: flex;
    align-items: center;
}
.profile-header-nav__header > img {
    margin-right: 16px;
}
.profile-header-nav__icon {
    margin-right: 14px;

    min-width: 48px;
    height: 48px;

    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    border: 1px solid rgb(78, 154, 165);
    background: rgb(255, 255, 255);
    border-radius: 50%;
}

.profile-header-nav__title {
    color: rgb(0, 0, 0);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: left;
}

.profile-header-nav__arrow {
    margin-left: 14px;
}

.profile-header-nav__item .sub-menu {
    width: calc(100% - 24px);
    display: none;

    position: absolute;

    top: calc(100% + 12px);
    left: 0;

    background-color: #fff;
    border-radius: 8px;

    z-index: 2;
}
.profile-header-nav__item .sub-list {
    padding: 6px 0;

    display: grid;
    grid-template-columns: 1fr;
}
.profile-header-nav__item .sub-list__item {
    padding: 6px 12px;

    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: left;
    text-decoration: none;

    transition: ease-in-out .1s;
    cursor: pointer;
}
.profile-header-nav__item .sub-list__item:hover {
    background-color: rgb(78, 154, 165);
    color: #fff;
}
.profile-header-nav__item .sub-list__item:not(:last-child) {
    border-bottom: 1px solid lightgray;
}
.profile-header-nav__item.opened .sub-menu {
    display: block;
}
.profile-header-nav__item.has-sub .profile-header-nav__header {
    user-select: none;
    cursor: pointer;
}

.profile-header .burger-btn {
    display: none;
}

.profile-header .burger-btn > span {
    background-color: #000;
}

@media (max-width:1440px) {
    .profile-header .text-h1 {
        font-size: 24px;
        font-weight: 400;
        line-height: 31px;
    }
}

@media (max-width:1320px) {
    .profile-header li:not(:last-child) {
        margin-right: 24px;
    }
}

@media (max-width:1200px) {
    .profile-header>.container {
        margin: 0 25px;
    }
    .profile-header .text-h1 {
        font-size: 18px;
        line-height: 22px;
    }
    .profile-header-nav__icon {
        margin-right: 7px;
        min-width: 32px;
        height: 32px;
    }
    .profile-header-nav__icon > img {
        width: 60%;
    }
    .profile-header-nav__title {
        font-size: 14px;
        line-height: 16px;
    }
}
@media (max-width:1200px) {
    .profile-header .burger-btn {
        display: block;
    }
}

@media (max-width:1000px) {
    .profile-header .profile-header-nav .profile-header-nav__item {
        display: none;
    }
    .profile-header {
        border-radius: 0px 0px 10px 10px;
    }
}