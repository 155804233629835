.code-tabs-container {
    max-width: 70vw;

    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
}

.tabs {
    display: flex;
    border-bottom: 1px solid #ddd;
}

.tabs button {
    flex: 1;
    padding: 10px;
    cursor: pointer;
    background-color: #f5f5f5;
    border: none;
    border-right: 1px solid #ddd;
    transition: background-color 0.3s;
}

.tabs button.active {
    background-color: #e0e0e0;
    font-weight: bold;
}

.tabs button:last-child {
    border-right: none;
}

.code-snippet {
    position: relative;
    padding: 10px;
}
.copy-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    background-color: rgb(78, 154, 165);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    transition: linear .1s;
}

.copy-button:hover {
    background-color: rgb(60, 136, 148);
}