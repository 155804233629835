.landing-header {
    padding: 24px 0;
}

.landing-header>.container {
    margin: 0 202px;
    padding: 21.5px 44px;

    box-sizing: border-box;
    border: 1px solid rgb(213, 213, 213);
    border-radius: 25px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background: rgb(255, 255, 255);
}

.landing-header__left,
.landing-header__right {
    display: flex;
    align-items: center;
}

.landing-header__right .language-switcher {
    margin-right: 30px;
}

.landing-header__logo {
    margin-right: 86px;

    width: 160px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
}
.landing-header__logo > img {
    width: 100%;
}

.landing-header-nav {
    display: flex;
    align-items: center;
}

.landing-header-nav__item {
    color: rgb(30, 30, 30);
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0%;
    text-align: center;
    text-decoration: none;

    transition: linear .1s;
}

.landing-header-nav__item:hover {
    color: rgb(78, 154, 165);
}

.landing-header-nav__item:not(:last-child) {
    margin-right: 40px;
}

.landing-header .language-switcher__title {
    color: #000;
}
.landing-header .primary-btn img {
    margin-left: 13px;
}

.landing-header .burger-btn {
    margin-left: 20px;

    display: none;
}
@media (max-width: 1620px) {
    .landing-header>.container {
        margin: 0 80px;
    }
}
@media (max-width: 1440px) {
    .landing-header>.container {
        margin: 0 20px;
    }
}
@media (max-width: 1200px) {
    .landing-header {
        padding: 10px 0;
    }
    .landing-header > .container {
        padding: 15px 25px;
    }
    .landing-header__logo {
        margin-right: 35px;

        width: 120px;
    }
    .landing-header-nav__item {
        font-size: 18px;
    }
    .landing-header-nav__item:not(:last-child) {
        margin-right: 25px;
    }
    .landing-header .primary-btn {
        font-size: 16px;
        line-height: 18px;
    }
}
@media (max-width: 850px) {
    .landing-header .landing-header-nav {
        display: none;
    }
    .landing-header .burger-btn {
        display: block;
    }
    .landing-header .landing-header__right > .primary-btn {
        display: none;
    }
}
@media (max-width: 600px) {

}
@media (max-width: 500px) {
    .landing-header>.container {
        margin: 0 8px;
        padding: 8px 12px;
        border-radius: 18px;
    }
    .landing-header .landing-header__right > .language-switcher {
        display: none;
    }
}
