.profile-layout {
    min-height: 100vh;

    display: grid;
    grid-template-columns: auto 1fr;

    background: rgb(245, 245, 246);
}

@media (max-width:1000px) { 
    .profile-layout {
        grid-template-columns: 1fr;
    }
}