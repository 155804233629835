.hint-wrapper {
    width: fit-content;

    display: inline-flex;
    align-items: center;
    position: relative;

    cursor: pointer;
}
.hint-wrapper:not(:first-child) {
    margin-left: 12px;
}
.hint-title {
    margin-right: 8px;
    font-family: Inter;
    font-size: 16px;
}

.hint-circle {
    width: 24px;
    height: 24px;

    border-radius: 50%;
    background-color: #4E9AA5;

    font-family: Inter;
    font-size: 14px;
    color: white;
    font-weight: bold;
    
    display: flex;
    align-items: center;
    justify-content: center;
}

.hint-content {
    position: absolute;
    min-width: 300px;
    width: fit-content;
    max-width: 500px;
    top: calc(100% + 12px);
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: white;
    padding: 8px;
    border-radius: 4px;
    white-space: pre-wrap;
    font-family: Inter;
    font-size: 14px;
    line-height: 18px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.2s;

    animation: forwards fadeOut ease-in-out .2s;
}

.hint-wrapper:hover .hint-content {
    opacity: 1;
}