.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    animation: forwards fadeOut linear .4s;

    z-index: 9999;
}

.overlay > .container {
    padding: 32px;
    position: relative;

    width: 100%;
    height: calc(100% - 64px);

    overflow-y: auto;
}

.popup {
    position: relative;
    margin: 0 auto;
    width: 70vw;
    min-height: 70vh;
    min-width: 300px;
    background: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    text-align: center;

    display: grid;
    grid-template-rows: auto 1fr auto;

    animation: forwards slideUp linear .4s;
}
.popup.small {
    width: 35vw;
    min-height: 50vh;
}
.popup__header {
    padding: 32px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}
.popup__content {
    padding: 32px;
}
.popup__footer {
    padding: 32px;
}
.popup__footer .primary-btn {
    width: 100%;
}
.close-btn {
    position: relative;

    width: 30px;
    height: 30px;

    background-color: transparent;
    border: none;

    cursor: pointer;
}

.close-btn > span {
    position: absolute;
    top:  0;
    left: 50%;
    
    display: block;

    width: 2px;
    height: 100%;

    background-color: rgb(78, 154, 165);
}
.close-btn > span:nth-child(1) {
    transform: rotate(45deg);
}
.close-btn > span:nth-child(2) {
    transform: rotate(-45deg);
}
@keyframes fadeOut {
    from {
        opacity: 0;
        backdrop-filter: blur(10px);
    }
    to {
        opacity: 1;
        backdrop-filter: blur(2px);
    }
}
@keyframes slideUp {
    from {
        transform: translateY(100vh);
    }
    to {
        transform: translateY(0%);
    }
}


@media (max-width: 1000px) {
    .overlay > .container {
        padding: 32px 0;
    }
}
@media (max-width: 600px) {
    .popup {
        width: 100%;
    }
    .popup.small {
        width: 100%;
    }
}