.landing-layout {
    min-height: 100%;

    background: rgb(245, 245, 246);
    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
}

html,
body,
#root {
    height: 100%;
}