.choose-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 22px;
}

.choose-list__item {
    padding: 17px 30px;
    border-radius: 15px;

    display: flex;
    align-items: center;

    background: rgb(245, 246, 247);
    border: 2px solid transparent;

    cursor: pointer;
    transition: linear .2s;
}

.choose-list__icon {
    margin-right: 24px;
}

.choose-list__title {
    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0%;
    text-align: left;
}

.choose-list__item.selected {
    border-color: rgba(78, 154, 165, 0.7);
}


@media (max-width: 650px) {
    .choose-list__title {
        font-size: 16px;
        line-height: 18px;
    }
}