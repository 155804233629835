.select-wrapper {
    min-width: 100%;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.select-wrapper.disabled .select__header {
    opacity: .5;
}
.label {
    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0%;
    text-align: left;
}

.select-wrapper:not(:last-child) {
    margin-bottom: 24px;
}

.select {
    width: 100%;

    position: relative;
}

.select__header {
    padding: 9.5px 21px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    box-sizing: border-box;
    border: 1px solid rgba(78, 154, 165, 0.5);
    border-radius: 8px;
    box-shadow: 0px 0px 8px 0px rgba(78, 154, 165, 0.5);
    background: rgb(255, 255, 255);

    cursor: pointer;
}

.select__arrow {
    transition: transform .2s;
}

.select.opened .select__arrow {
    transform: rotate(180deg);
}

.select__title {
    max-width: 100%;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: left;
}
.select__search {
    padding: 9.5px 21px;
    width: 100%;

    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: left;

    background-image: url(../../../public/search.svg);
    background-position: calc(100% - 55px) center;
    background-size: auto 65%;
    background-repeat: no-repeat;
    border: none;
    border-bottom: 1px solid #4E9AA5;
    outline: none;
}
.no-items {
    padding: 10px 5px;
    text-align: center;
}
.select.opened .select__header {
    border-radius: 8px 8px 0 0;
}

.select__content {
    display: none;

    width: 100%;

    position: absolute;
    top: 100%;
    left: 0;

    box-sizing: border-box;
    border: 1px solid rgba(78, 154, 165, 0.5);
    border-top: none;
    border-radius: 0 0 8px 8px;
    box-shadow: 0px 0px 8px 0px rgba(78, 154, 165, 0.5);
    background-color: #fff;

    overflow-x: hidden;
    overflow-y: auto;
    max-height: 200px;

    z-index: 5;
}

/* width */
.select__content::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.select__content::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.select__content::-webkit-scrollbar-thumb {
    background: #4E9AA5;
}

.select.opened .select__content {
    display: block;
}

.select .sub-list__item {
    padding: 12px 21px;

    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: left;

    cursor: pointer;
    transition: color ease-in-out .1s;
}

.select .sub-list__item:hover {
    background: rgb(244, 244, 244);
}

.select .sub-list__item.selected {
    font-weight: bold;
    color: #4E9AA5;

    background-image: url(../../../public/check-svgrepo-com.svg);
    background-position: calc(100% - 12px) center;
    background-size: 24px 24px;
    background-repeat: no-repeat;
}