.telegram-btn {
    position: fixed;

    bottom: 20px;
    right: 20px;

    width: 60px;
    height: 60px;

    background: transparent;
    border: 0;
    border-radius: 50%;

    transition: ease-in-out .1s;
    cursor: pointer;
}
.telegram-btn:hover {
    opacity: .8;
}
.telegram-btn > img {
    width: 100%;
    height: 100%;
}