.title {
  padding: 30px 0;

  @media screen and (max-width: 500px) {
    padding: 15px 0;
    text-align: left;
  }
}
a {
  color: inherit;
  text-decoration: none;
}
.contacts-list {
  width: fit-content;
  height: fit-content;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 49px;

  @media (max-width: 620px) {
    grid-template-columns: 1fr;
  }

  img {
    margin-right: 11px;

    width: 36px;
    height: 36px;
  }
}
.contacts-main {
  max-width: 1520px;
  margin: 0 auto;
  width: 100%;
  padding-bottom: 64px;

  @media (max-width: 1540px) {
    max-width: 1320px;
  }
  @media (max-width: 1340px) {
    max-width: 1020px;
  }
  @media (max-width: 1040px) {
    max-width: 600px;
  }
  @media (max-width: 620px) {
    max-width: unset;
    width: calc(100% - 40px);
    margin: 0 20px;
  }
}
.separator {
  width: 100%;
  height: 1px;

  background-color: rgb(78, 154, 165);
}
.p-text {
  color: rgb(0, 0, 0);
  font-family: '__Inter_a943a7', '__Inter_Fallback_a943a7';
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0%;
  text-align: left;
}

.contacts-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 1340px) {
    grid-template-columns: 1fr;
    gap: 60px;
  }
}

.contacts-map {
  padding: 10px;
  height: 318px;

  box-sizing: border-box;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 8px;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.message {
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 20px;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }

  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
}

.text {
  font-size: 18px;
  line-height: 1.3;
  display: inline-block;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }

  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
}

.paddingTop {
  padding-top: 15px;
}

.mainList {
  list-style-type: none;
  counter-reset: num;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding-bottom: 50px;

  .mainItem {
    font-size: 18px;
    line-height: 1.2;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }

    @media screen and (max-width: 500px) {
      font-size: 14px;
    }

    &::before {
      content: counter(num) '.';
      font-size: 18px;
      font-weight: 700;
      padding-right: 10px;
      counter-increment: num;

      @media screen and (max-width: 768px) {
        font-size: 16px;
      }

      @media screen and (max-width: 500px) {
        font-size: 14px;
      }
    }

    .subtitle {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.2;

      @media screen and (max-width: 768px) {
        font-size: 16px;
      }

      @media screen and (max-width: 500px) {
        font-size: 14px;
      }
    }

    .subList {
      padding-top: 15px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      counter-reset: subNum;
      list-style-type: none;
      font-size: 18px;

      @media screen and (max-width: 768px) {
        font-size: 16px;
      }

      @media screen and (max-width: 500px) {
        font-size: 14px;
      }

      .item {
        font-size: 18px;

        @media screen and (max-width: 768px) {
          font-size: 16px;
        }

        @media screen and (max-width: 500px) {
          font-size: 14px;
        }

        &::before {
          content: counter(num) '.' counter(subNum);
          counter-increment: subNum;
          padding-right: 10px;
          font-size: 18px;

          @media screen and (max-width: 768px) {
            font-size: 16px;
          }

          @media screen and (max-width: 500px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}