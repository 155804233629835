.support-main>.container {
    margin: 50px 202px;

    @media (max-width: 1620px) {
        margin: 50px 80px;
    }

    @media (max-width: 1440px) {
        margin: 50px 20px;

        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 800px) {
        margin: 50px 20px;

        grid-template-columns: 1fr;
    }
}
.support-catalogue > .container {
    margin: 50px 202px;
    
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    @media (max-width: 1620px) {
        margin: 50px 80px;
    }

    @media (max-width: 1440px) {
        margin: 50px 20px;

        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 800px) {
        margin: 50px 20px;

        grid-template-columns: 1fr;
    }
}

.support-card {
    padding: 30px;

    border-radius: 3px;

    box-shadow: 0px 30px 60px 0px rgba(58, 58, 244, 0.1);
    background: rgb(255, 255, 255);

    &__header {
        display: flex;
        align-items: center;
    }

    &__header:not(:last-child) {
        margin-bottom: 20px;
    }

    &__icon {
        min-width: 50px;
        min-height: 50px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50%;
        background: rgb(139, 194, 202);
    }

    &__icon:not(:last-child) {
        margin-right: 16px;
    }

    &__title {
        color: rgb(6, 6, 25);
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
        line-height: 23px;
        letter-spacing: 0%;
        text-align: left;
    }

    &__nav {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }

    &__link {
        padding-left: 28px;

        background-image: url(../../public/document_icon.svg);
        background-repeat: no-repeat;
        background-position: left center;

        color: rgb(6, 6, 25);
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0%;
        text-align: left;

        transition: linear .1s;
    }

    &__link:hover {
        opacity: .6;
    }
}

.support-article {
    border-radius: 10px;

    box-shadow: 0px 30px 60px 0px rgba(58, 58, 244, 0.1);
    background: rgb(255, 255, 255);

    &__title {
        color: rgb(6, 6, 25);
        font-family: Inter;
        font-size: 36px;
        font-weight: 700;
        line-height: 45px;
        letter-spacing: 0%;
        text-align: left;

        @media (max-width: 800px) {
            font-size: 26px;
            font-weight: 700;
            line-height: 34px;
        }
    }

    &__header {
        border-bottom: 1px solid lightgray;
    }

    &__header:not(:last-child) {
        margin-bottom: 20px;
    }

    &__header > .container {
        padding: 60px;
        padding-bottom: 20px;

        display: flex;
        align-items: center;
    
        @media (max-width: 800px) {
            padding: 20px;
            padding-bottom: 10px;
        }
    }

    &__content {
        padding: 0 60px;

        font-family: Inter;
        font-size: 16px;
        line-height: 1.8;
        color: #333;
        border-radius: 8px;

        @media (max-width: 800px) {
            padding: 0 20px;
        }

        ol, ul {
            list-style: inherit;
        }

        p {
            margin-bottom: 20px;
            color: #444;
            font-size: 18px;
            line-height: 1.6;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            @media (max-width: 800px) {
                margin-bottom: 8px;

                font-size: 14px;
            }
        }

        ul,
        ol {
            margin: 20px 0;
            padding-left: 30px;

            li {
                margin-bottom: 10px;
                font-size: 16px;
                line-height: 1.6;

                &:last-child {
                    margin-bottom: 0;
                }

                @media (max-width: 800px) {
                    margin-bottom: 4px;
                    font-size: 14px;
                }
            }

            // Стиль для нумерованного списка
            ol {
                list-style-type: decimal;
            }

            // Стиль для ненумерованного списка
            ul {
                list-style-type: disc;

                // Для второго уровня списков
                ul {
                    list-style-type: circle;
                    margin-top: 10px;
                }
            }
        }

        blockquote {
            margin: 30px 0;
            padding: 20px;
            background-color: #ececec;
            border-left: 5px solid #ccc;
            font-style: italic;
            color: #555;

            p {
                margin: 0;
            }
        }

        h2,
        h3,
        h4 {
            margin-top: 40px;
            color: #222;
            font-weight: bold;

            @media (max-width: 800px) {
                margin-top: 10px;
            }
        }

        h2 {
            font-size: 26px;

            @media (max-width: 800px) {
                font-size: 22px;
            }
        }

        h3 {
            font-size: 24px;

            @media (max-width: 800px) {
                font-size: 18px;
            }
        }

        h4 {
            font-size: 20px;

            @media (max-width: 800px) {
                font-size: 16px;
            }
        }

        img {
            max-width: 100%;
            height: auto;
            display: block;
            margin: 20px 0;
        }

        pre,
        code {
            background-color: #f4f4f4;
            padding: 10px;
            border-radius: 4px;
            font-family: 'Courier New', Courier, monospace;
            overflow-x: auto;
        }

        pre {
            margin: 20px 0;
        }

        code {
            padding: 2px 4px;
        }
    }

    &__footer > .container {
        padding: 60px;
        padding-bottom: 20px;

        display: flex;
        align-items: center;

        @media (max-width: 800px) {
            padding: 0 20px;
            padding-bottom: 10px;
        }
    }
}
