.breadcrumb {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    border-radius: 0.375rem;
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #000;
    content: "»";
}

.breadcrumb-item a {
    color: rgb(78, 154, 165);
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: center;
    text-decoration: none;

    transition: linear .1s;
}

.breadcrumb-item a:hover {
    color: rgb(45, 111, 121);
}

.breadcrumb-item.active {
    color: #6c757d;
}