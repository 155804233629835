.profile-sidebar {
    height: 100%;

    display: flex;
    flex-direction: column;

    border-left: 1px solid rgba(136, 136, 136, 0.2);
    border-right: 1px solid rgba(136, 136, 136, 0.2);
    background: rgb(255, 255, 255);
}

.logo {
    position: relative;

    min-height: 89px;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;
}
.logo > img {
    width: 108px;
    height: 34px;

    z-index: 2;
}
.profile-sidebar .logo::after {
    content: "";

    position: absolute;
    top: 0;
    left: 0;

    width: calc(100% + 1px);
    height: 100%;

    background-color: white;

    z-index: 0;
}
.profile-nav {
    display: flex;
    flex-direction: column;
}

.profile-nav__link {
    position: relative;

    padding: 10.5px 23px;

    display: flex;
    flex-direction: column;
    align-items: center;

    text-decoration: none;
}

.profile-nav__link>img {
    width: 32px;
    height: 32px;

    filter: grayscale(1);

    transition: linear .2s;
}
.profile-nav__title {
    padding: 18px 49px;

    color: #000;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0%;
    text-align: center;
}
.profile-nav__link>p {
    max-width: 100px;

    color: gray;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0%;
    text-align: center;

    transition: linear .2s;
}
.profile-nav__link>p:not(:first-child) {
    margin-top: 10px;
}
.profile-nav__link::before {
    content: "";

    width: 6px;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    background-color: transparent;
    border-radius: 8px;

    transition: linear .2s;
}

.profile-nav__link.selected::before {
    background-color: #4E9AA5;
}
.profile-nav__link.selected>p {
    color: #4E9AA5;
}
.profile-nav__link.selected>img {
    filter: grayscale(0);
}



@media (max-width:1000px) { 
    .profile-sidebar {
        display: none;
    }
}