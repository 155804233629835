.product-table {
    width: 100%;
}

.product-table th {
    padding: 21px 32px;

    background: rgba(203, 223, 227, 0.5);

    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0%;
    text-align: center;
}

.product-table tbody:before {
    content: "@";
    display: block;
    line-height: 31px;
    text-indent: -99999px;
}

.product-table th:first-child {
    border-radius: 25px 0 0 25px;

    text-align: left;
}

.product-table th:last-child {
    border-radius: 0 25px 25px 0;

    text-align: right;
}

.product-table td {
    padding: 15.5px 32px;

    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0%;
    text-align: center;

    transition: ease-in-out .1s;
}

.product-table tbody tr {
    cursor: pointer;
}

.product-table tr.selected td {
    color: #fff;
    background: rgba(78, 154, 165, 0.7);
}

.product-table tr.selected td:first-child {
    border-radius: 10px 0 0 10px;
}

.product-table tr.selected td:last-child {
    border-radius: 0 10px 10px 0;
}

.product-table td:first-child {
    text-align: left;
}

.product-table td:last-child {
    text-align: right;
}

.product-table tr:not(:first-child) td {
    border-top: 1px solid rgba(78, 154, 165, 0.3);
}
.product-table tr + tr.selected td {
    border-color: transparent;
}

.product-table tr.selected + tr td {
    border-color: transparent;
}


@media (max-width: 650px) {
    .product-table th {
        padding: 12px 16px;

        font-size: 16px;
        line-height: 18px;
    }
    .product-table td {
        padding: 12px 16px;

        font-size: 14px;
        line-height: 16px;
    }
}