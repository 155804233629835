.date-picker {
    min-width: 250px;
    position: relative;

    .checkbox-wrapper:not(:last-child) {
        margin-bottom: 12px !important;
    }

    .sub-menu {
        position: absolute;

        top: calc(100% + 12px);

        padding: 16px 0;

        border-radius: 12px;
        box-shadow: 0px 4px 40px 0px rgb(238, 238, 238), 0px 4px 8px 0px rgba(66, 71, 76, 0.05), 0px 0px 0.5px 0px rgba(66, 71, 76, 0.32);
        background: rgb(255, 255, 255);

        z-index: 2;
    }

    &__title {
        background: #fff;
        border: 1px solid #4e9aa580;
        border-radius: 8px;
        box-shadow: 0 0 8px 0 #4e9aa580;
        box-sizing: border-box;
        padding: 13px 21px;

        cursor: pointer;

        transition: ease-in-out .1s;

        &:hover {
            background-color: #f8f9fa;
        }
    }

    &__menu {
        display: none;
        flex-direction: column;
        padding: 16px;
        border-top: 1px solid rgb(244, 244, 245);

        &.active {
            display: flex;
        }
    }

    &__period {
        padding: 0 16px;
    }

    &__header {
        padding: 0 16px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        button {
            width: 40px;
            height: 40px;

            background-color: transparent;
            border: none;
            font-size: 18px;
            cursor: pointer;
            color: #4e9aa5;

            box-sizing: border-box;
            border-radius: 6px;

            /* Small */
            box-shadow: 0px 1px 5px -1px rgba(17, 12, 34, 0.1);

            &:hover {
                color: #3c8894;
            }
        }
    }

    &__content {
        padding: 0 16px;
    }

    &__days-grid {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 4px;
    }

    &__day {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 1px solid transparent;
        border-radius: 4px;
        transition: background-color 0.3s, border-color 0.3s;

        &:hover {
            color: #fff;
            background-color: #3c8894;
        }

        &.selected {
            background-color: #4e9aa5;
            color: white;

            &:hover {
                background-color: #3c8894;
            }
        }
        &.disabled {
            opacity: .6;
            cursor: not-allowed;

            &:hover {
                color: #000;
                background-color: transparent;
            }
        }
    }

    &__period {
        margin-bottom: 16px;

        h4 {
            margin-bottom: 8px;
            font-size: 14px;
            font-weight: 600;
            color: rgb(9, 9, 11);
        }

        .checkbox-wrapper {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 8px;

            label {
                font-size: 14px;
                color: #495057;
            }

            input[type="radio"] {
                accent-color: #4e9aa5;
                cursor: pointer;
            }
        }
    }

    &__footer {
        padding: 0 16px;

        display: flex;
        justify-content: flex-end;
        gap: 8px;
        margin-top: 16px;

        button {
            padding: 8px 12px;
            width: 100%;

            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;

            transition: ease-in-out .1s;
        }
    }

    &__clear {
        background-color: #f8f9fa;
        color: #000;

        &:hover {
            background-color: #e9ecef;
        }
    }

    &__apply {
        background-color: #4e9aa5;
        color: #fff;

        &:hover {
            background-color: #3c8894;
        }
    }
}