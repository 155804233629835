.input-wrapper_fade {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.tag {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 14px;
}

.remove-button {
    background: none;
    border: none;
    font-size: 14px;
    color: #ff0000;
    margin-left: 5px;
    cursor: pointer;
}