.input-wrapper {
    padding: 30px;
}

.copy-btn {
    padding: 8px 12px;
    background-color: #4e9aa5;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.copy-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.copy-btn:hover:not(:disabled) {
    background-color: #327b86;
}

.copy-btn:active:not(:disabled) {
    background-color: #327b86;
}