body.no-scroll {
    max-height: 100vh;
    overflow-y: hidden;
}

body {
    font-family: Inter;
}
.tooltip {
    position: absolute!important;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
}
b {
    font-weight: bold;
}

[data-tooltip] {
    position: relative;
}

[data-tooltip]:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    background: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px!important;
    white-space: nowrap;
    z-index: 10;
    top: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
    transition: opacity 0.1s ease-in-out;
    pointer-events: none;

    z-index: 2;
}

[data-tooltip]::after {
    content: '';
    position: absolute;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    pointer-events: none;

    z-index: 2;
}
@media screen and (max-width: 768px) {
    [data-tooltip]:hover::after {
        width: 100%;
        max-width: 80vw;
        white-space: wrap;
        height: auto;
    }
}
.link {
    color: #58a5b0;
    transition: linear .1s;
}

.link:hover {
    color: #2f6e77;
}

.separator {
    margin: 15px 0;

    width: 100%;
    height: 1px;

    background-color: rgba(78, 154, 165, 0.3);
}

.bold {
    font-weight: bold !important;
}

.h2-title {
    font-size: 32px;
    padding-bottom: 32px;
    font-weight: 700;
}

.section-h2 {
    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0%;
    text-align: center;
}

.section-p {
    color: rgb(136, 136, 136);
    font-family: Inter;
    font-size: 25px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0%;
    text-align: center;
}

.section-h2+.section-p {
    margin-top: 18px;
}

.not-found-container {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;

    margin-left: 20px;
    margin-right: 20px;
}

.not-found-container h1 {
    font-size: 2em;
    margin-bottom: 20px;
}

.not-found-container p {
    font-size: 1.2em;
    margin-bottom: 30px;
}

.home-link {
    color: #4E9AA5;
    text-decoration: none;
    font-size: 1.2em;
}

.home-link:hover {
    text-decoration: underline;
}

/* Page sections *Start* */

.partnership-page > .container {
    width: 100%;
    display: grid
;
    gap: 12px;
    grid-auto-flow: dense;
    grid-template-columns: repeat(12, minmax(0, 1fr));
}

.page-section {
    padding: 40px;

    height: fit-content;

    background-color: #fff;
    border-radius: 20px;
}
.page-section:not(:last-child) {
    margin-bottom: 50px;
}
.page-section__header {

}
.page-section__header:not(:last-child) {
    margin-bottom: 20px;
}
.page-section__h2 {
    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0%;
    text-align: left;
}
.page-section .table {
    width: 100%;
}
.partnership-page .page-section:not(:last-child) {
    margin: 0;
}
/* Page sections *Stop* */

/* Table *Start* */

.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 24px;
}

.table th {
    padding: 18px 0;

    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: center;
}

.table td {
    padding: 18.5px 0;

    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: center;
}

.table tr td:first-child::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 10px;

    background-color: #4E9AA5;
    border-radius: 8px 0 0 8px;
}

.table tr.success td:first-child::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 10px;

    background-color: #61C027;
    border-radius: 8px 0 0 8px;
}

.table tr.error td:first-child::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 10px;

    background-color: #F12E2E;
    border-radius: 8px 0 0 8px;
}

.table td:first-child {
    padding-left: 10px;
    border-radius: 0 8px 0 8px;
}

.table td:last-child {
    border-radius: 0 0 8px 8px;
}

.table tr {
    position: relative;
    box-shadow: 0px 0px 8px 0px rgba(78, 154, 165, 0.5);

    border-radius: 8px;
}


.table_fade {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 24px;
}

.table_fade th {
    padding: 18px 22px;

    box-sizing: border-box;

    background: rgb(255, 255, 255);

    color: rgb(0, 0, 0);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0%;
    text-align: center;
}

.table_fade thead {
    box-shadow: 0px 0px 8px 0px rgba(78, 154, 165, 0.5);
    border-radius: 8px;
    overflow: hidden;
}

.table_fade th:first-child {
    border-radius: 8px 0 0 8px;
}

.table_fade th:last-child {
    border-radius: 0 8px 8px 0;
}

.table_fade td {
    padding: 18.5px 22px;

    color: #000;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0%;
    vertical-align: middle;
    text-align: center;
}

.table_fade td:first-child {
    border-radius: 0 8px 0 8px;
}

.table td:last-child {
    border-radius: 0 0 8px 8px;
}

.table_fade tr {
    position: relative;
    box-shadow: 0px 0px 8px 0px rgba(78, 154, 165, 0.5);

    border-radius: 8px;
}

/* Table *Stop* */


/* UI Kit *Start* */

.primary-btn {
    padding: 12px 24px;
    display: block;

    border-radius: 15px;

    background: rgb(78, 154, 165);
    box-sizing: border-box;
    border: 1px solid transparent;

    color: rgb(255, 255, 255);
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0%;
    text-align: center;
    text-decoration: none;

    cursor: pointer;
    transition: background linear .2s;
    user-select: none;
    
    z-index: 2;
}

.primary-btn:hover {
    background: rgb(60, 136, 148);
}

.primary-btn:disabled {
    background: rgba(78, 154, 165, 0.5);
    cursor: not-allowed;
}

.primary-btn_unfilled {
    color: rgb(78, 154, 165);
    background: transparent;
}

.primary-btn_unfilled:disabled {
    background: transparent;
}

.primary-btn_unfilled:hover {
    color: #fff;
}
.buttons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.popup__footer .buttons-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
}
.cancel-btn {
    padding: 12px 24px;
    display: block;

    border-radius: 15px;

    background: rgb(255, 255, 255);
    box-sizing: border-box;
    border: 1px solid rgb(78, 154, 165);

    color: rgb(78, 154, 165);
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0%;
    text-align: center;
    text-decoration: none;

    cursor: pointer;
    transition: background linear .2s;
    user-select: none;
}
.cancel-btn:hover {
    background: rgb(78, 154, 165);
    color: rgb(255, 255, 255);
}
.text-to-delete {
    color: #000;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0%;
    text-align: center;
}
.text-to-delete:not(:last-child) {
    margin-bottom: 30px;
}
.delete-btn {
    padding: 12px 24px;
    display: block;

    border-radius: 15px;

    background: rgb(255, 255, 255);
    box-sizing: border-box;
    border: 1px solid rgb(255, 0, 0);

    color: rgb(255, 0, 0);
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0%;
    text-align: center;
    text-decoration: none;

    cursor: pointer;
    transition: background linear .2s;
    user-select: none;
}
.delete-btn:hover {
    background: #ff4d4d;
    color: rgb(255, 255, 255);
}
.delete-btn:disabled {
    opacity: .5;
    cursor: not-allowed;
}
.delete-btn:disabled:hover {
    background: transparent;
    color: rgb(255, 0, 0);
}
.delete-button {
    background-color: #ff4d4d;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}
.delete-button > img {
    width: 20px;
    height: 20px;
}
.delete-button:hover {
    background-color: #e60000;
}

.delete-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
    opacity: 0.6;
}

.edit-button {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #4E9AA5;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}
.edit-button > img {
    width: 20px;
    height: 20px;
}
.edit-button:hover {
    background-color: #2f6e77;
}

.edit-button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.input-wrapper {
    position: relative;

    padding: 20px 25px;

    display: flex;
    align-items: center;

    border-radius: 25px;
    background: rgb(238, 238, 238);
}
.input-wrapper__info {
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
    cursor: pointer;
}
input:disabled {
    cursor: not-allowed;
    opacity: .5;
}

.input-wrapper:not(:last-child) {
    margin-bottom: 20px;
}

.input-wrapper__icon {
    height: 26px;
    width: 26px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.input-wrapper__icon>img {
    width: 100%;
    height: 100%;
}

.input-wrapper__icon:not(:last-child) {
    margin-right: 16px;
}

.input {
    width: 100%;

    border: none;
    background-color: transparent;

    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: left;

    outline: none;
}

.input.bold {
    font-weight: 700;
}

.input-wrapper .input {
    width: 100%;
}

.input::placeholder {
    color: rgb(133, 133, 133);
}

.space-between {
    justify-content: space-between;
    align-items: center;
}

.flex-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    gap: 8px;
}

.flex-wrapper .input-wrapper:not(:last-child) {
    margin-bottom: 0;
}

.flex-wrapper>* {
    margin-right: 12px;
}

.checkbox {
    position: relative;
    appearance: none;
    margin: 0;

    min-width: 24px;
    height: 24px;
}

.checkbox::before {
    position: absolute;
    top: 0;
    left: 0;

    content: "";
    display: block;

    min-width: 100%;
    height: 100%;

    box-sizing: border-box;
    border-radius: 50%;
    border: 2px solid rgb(199, 199, 199);

    transition: linear .1s;
}

.checkbox::after {
    position: absolute;
    top: 0;
    left: 0;

    content: "";
    display: block;

    min-width: 100%;
    height: 100%;

    background: transparent;

    transition: background linear .1s;
}

.checkbox:checked:before {
    background-color: #4E9AA5;
    border-color: #4E9AA5;
}

.checkbox:checked::after {
    background-image: url(../../public/checkmark.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 80% 80%;
}

.checkbox-wrapper {
    display: flex;
    flex-direction: row !important;
    align-items: center;
}




.switch {
    position: relative;
    appearance: none;
    margin: 0;

    min-width: 49px;
    height: 24px;
}

.switch::before {
    position: absolute;
    top: 0;
    left: 0;

    content: "";
    display: block;

    min-width: 100%;
    height: 100%;

    box-sizing: border-box;
    border: 1px solid rgb(149, 149, 149);
    border-radius: 30px;

    transition: linear .1s;
}

.switch::after {
    position: absolute;
    top: 3px;
    left: 3px;

    content: "";
    display: block;

    min-width: 18px;
    height: calc(100% - 6px);

    background: rgb(149, 149, 149);
    border-radius: 50%;

    transition: linear .1s;
}

.switch:checked:before {
    border-color: #4E9AA5;
    background-color: rgb(78, 154, 165);
}

.switch:checked::after {
    background-color: #fff;
    transform: translateX(calc(100% + 7px));
}

.remove-btn {
    width: fit-content;

    cursor: pointer;
    transition: linear .2s;
}

.table_fade .remove-btn {
    margin: 0 auto;
}

.remove-btn:hover {
    opacity: .6;
}

.form-header .checkbox-wrapper .label {
    order: 1;
}

.form-header .checkbox-wrapper .switch {
    margin-right: 0;
    margin-left: 12px;
    order: 2;
}

.checkbox-wrapper .checkbox:not(:last-child) {
    margin-right: 12px;
}

.checkbox-wrapper .switch:not(:last-child) {
    margin-right: 12px;
}

.form .checkbox-wrapper:not(:last-child) {
    margin-bottom: 30px;
}

.textarea {
    padding: 15px;
    width: 100%;

    box-sizing: border-box;
    border: 1px solid rgba(78, 154, 165, 0.5);
    border-radius: 8px;

    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: left;

    background: rgb(255, 255, 255);

    resize: vertical;
    outline: none;
    transition: ease-in-out .2s;
}

.textarea:hover {
    box-shadow: 0px 0px 8px 0px rgba(78, 154, 165, 0.2);
}

.textarea:focus {
    box-shadow: 0px 0px 8px 0px rgba(78, 154, 165, 0.5);
}

.text-h2 {
    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0%;
    text-align: left;
}

.text-h3 {
    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0%;
    text-align: left;
}

/* UI Kit *Stop* */

/* Auth page *Start* */

.auth-page {
    min-height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    background: rgb(245, 254, 255);
}

.auth-main {
    min-width: 65%;
    max-width: 65%;
    min-height: 650px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);

    border-radius: 40px;

    box-shadow: 0px 20px 60px 1px rgba(0, 0, 0, 0.25);
    background: rgb(255, 255, 255);
}

.auth-main__side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.auth-main__side .auth-h1 {
    text-align: center;
}

.auth-h1 {
    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 35px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0%;
    text-align: left;
}

.auth-p {
    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 22px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0%;
    text-align: center;
}

.auth-main__side form .primary-btn {
    width: 100%;
}

.auth-main__side:first-child {
    border-radius: 40px;
    background: rgb(212, 234, 237);
}

.auth-main__side:first-child .auth-h1 {
    margin-bottom: 15px;
}

.auth-main__side:first-child>.container {
    padding: 0 20px;
}

.auth-main__side>form {
    padding: 0 20px;
}

.auth-main__side:first-child .auth-p {
    margin-bottom: 32px;
    max-width: 504px;
}

/* Auth page *Stop* */

.balance-deposit-form .input-wrapper {
    background-color: rgb(255, 255, 255);
    border-radius: 8px;

    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    background: rgb(255, 255, 255);
}

.form .input-wrapper_fade:not(:last-child) {
    margin-bottom: 24px;
}

.balance-deposit-form .text-h2 {
    margin-bottom: 30px;
}

.auth-link {
    color: rgb(133, 133, 133);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: center;
    text-decoration: none;
}

.auth-link:hover {
    text-decoration: underline;
}


.auth-social-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.auth-social-list:not(:first-child) {
    margin-top: 24px;
}

/* Wallet Page *Start* */

.profile-main {
    padding: 53px 0;
    min-height: calc(100% - 194px);
}

.profile-main>.container {
    margin: 0 30px;
}

.balance-deposit-form {
    padding: 50px 0;
    max-width: 665px;

    border-radius: 25px;

    box-shadow: 0px 0px 30px 2px rgba(78, 154, 165, 0.2);
    background: rgb(255, 255, 255);
}

.balance-deposit-form>.container {
    margin: 0 60px;
}

.balance-deposit-form .section-h2 {
    margin-bottom: 30px;
}

.balance-deposit-form__footer {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 15px;
}

.balance-deposit-form__footer .input-wrapper {
    margin: 0;
    padding: 12px 26px;
}

.balance-deposit-form__footer .primary-btn {
    padding: 13px 51px;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0%;
    border-radius: 8px;
}

.deposit-variants {
    display: grid;
    grid-template-columns: 1fr;
    gap: 18px;
}

.deposit-variants:not(:last-child) {
    margin-bottom: 18px;
}

.deposit-variants__item {
    padding: 11.5px 26px;

    display: flex;
    align-items: center;

    border-radius: 8px;

    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    background: rgb(255, 255, 255);
    border: 1px solid transparent;

    transition: linear .1s;
}

.deposit-variants__item .checkbox {
    margin-right: 28px;
}

.deposit-variants__item label {
    display: flex;
    align-items: center;
}

.deposit-variants__wrapper {
    width: 100%;
}

.deposit-variants__icons {
    margin-left: auto;
    width: fit-content;

    display: flex;
    align-items: center;
}

.deposit-variants__icons> :not(:last-child) {
    margin-right: 5px;
}

.label {}

.label:not(:last-child) {
    display: block;

    margin-bottom: 13px;
}

.deposit-variants__icon {
    margin-right: 10px;

    filter: grayscale(1);

    transition: linear .2s;
}

.deposit-variants__item.selected .deposit-variants__icon {
    filter: grayscale(0);
}

.deposit-variants__title {
    color: rgb(199, 199, 199);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0%;
    text-align: left;
}

.deposit-variants__item.selected {
    border-color: #4E9AA5;
}

.deposit-variants__item.selected .deposit-variants__title {
    color: rgb(78, 154, 165);
}

.balance-deposit-form-wrapper {
    max-width: 1400px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 14px;
}

.balance-deposit-form-wrapper>* {
    min-width: 100%;
}

.deposit-history {
    padding: 50px 0;
    max-width: 1400px;

    border-radius: 25px;

    box-shadow: 0px 0px 30px 2px rgba(78, 154, 165, 0.2);
    background: rgb(255, 255, 255);
}

.deposit-history:not(:first-child) {
    margin-top: 42px;
}

.deposit-history>.container {
    margin: 0 60px;
}

.deposit-history .section-h2 {
    margin-bottom: 24px;
}

/* Account banners *Start* */

.banner-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}

.banner-item {
    padding: 20px 0;
    min-height: calc(268px - 40px);

    border-radius: 8px;
    background: rgb(255, 255, 255);
    background-repeat: no-repeat;
    background-position: 90% center;
}

.banner-item>.container {
    margin: 0 10px;
}

.banner-item .text-h2 {
    margin-bottom: 20px;

    font-size: 20px;
    line-height: 24px;
}

.pros-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
}

.banner-item .section-h2:not(:last-child) {
    margin-bottom: 33px;
}

.pros-list:not(:last-child) {
    margin-bottom: 33px;
}

.pros-list__item {
    padding: 19px 29px 19px 85px;
    width: fit-content;

    box-sizing: border-box;
    border: 1px solid rgba(78, 154, 165, 0.5);
    border-radius: 15px;

    box-shadow: 0px 0px 10px 0px rgba(78, 154, 165, 0.2);
    background: rgb(255, 255, 255);
    background-image: url(../../public/pros.svg);
    background-repeat: no-repeat;
    background-position: 29px center;

    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: left;
}

.banner-item .primary-btn {
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
}

.banner-item .pros-list {
    gap: 16px;
}

.banner-item .pros-list__item {
    padding: 0;
    padding-left: 28px;

    background-size: 24px 24px;
    background-position: 0 center;
    border: none;
    box-shadow: none;
}

.form textarea:not(:last-child) {
    margin-bottom: 30px;
}

.grid-wrapper .primary-btn {
    height: fit-content;
}

/* Account banners *Stop* */

.proxylist-wrapper>*:not(:last-child) {
    margin-bottom: 42px;
}

.proxylist-wrapper>.grid-wrapper:first-child>.form:first-child {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.proxylist-wrapper>.grid-wrapper:first-child>.form:last-child {
    display: flex;
    flex-direction: column;
}

.proxylist-wrapper>.grid-wrapper:first-child>.form:last-child>.textarea {
    height: 100%;
    resize: none;
}

.form {
    padding: 50px 60px;

    background-color: #fff;
    box-shadow: 0px 0px 30px 2px rgba(78, 154, 165, 0.2);
    border-radius: 20px;
}

.change-data-form {
    max-width: 700px;
}

.change-data-form .input-wrapper_fade {
    max-width: 300px;
}

.form .grid-wrapper:not(:last-child) {
    margin-bottom: 30px;
}

.grid-wrapper>* {
    margin: 0 !important;
}

.form-header {
    margin-bottom: 30px;
}

.form-header .select-wrapper {
    min-width: fit-content;
}

/* Shop *Start* */
.shop-wrapper {
    position: relative;

    display: grid;
    grid-template-columns: 1fr auto;
    gap: 31px;
}

.shop-product-wrapper {
    padding: 50px 60px;

    border-radius: 25px;

    box-shadow: 0px 0px 30px 2px rgba(78, 154, 165, 0.2);
    background: rgb(255, 255, 255);
}

.shop-product-wrapper .text-h2 {
    margin-bottom: 30px;
}

.summary {
    height: fit-content;

    position: sticky;
    top: 20px;
    right: 0;

    padding: 50px 60px;

    border-radius: 25px;

    box-shadow: 0px 0px 30px 2px rgba(78, 154, 165, 0.2);
    background: rgb(255, 255, 255);
}

.summary-header {
    padding-bottom: 14.5px;
}

.summary-content {
    padding: 31px 0;

    border-top: 1px solid rgba(78, 154, 165, 0.3);
    border-bottom: 1px solid rgba(78, 154, 165, 0.3);
}

.summary-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
}

.summary-list__item {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.summary-list__name {
    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0%;
    text-align: left;
}

.summary-list__value {
    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0%;
    text-align: left;
}

.summary-list__value.bold {
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0%;
}

.green {
    color: rgb(78, 154, 165) !important;
}

.summary-footer .summary-list {
    margin-bottom: 30px;
}

.summary-footer {
    padding-top: 30px;
}

.summary-footer .primary-btn {
    padding: 13px 0;
    width: 100%;
    border-radius: 8px;

    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0%;
}

.shop-payment-form {
    padding: 50px 60px;

    border-radius: 25px;

    box-shadow: 0px 0px 30px 2px rgba(78, 154, 165, 0.2);
    background: rgb(255, 255, 255);
}

.shop-payment-form__header {
    padding-bottom: 14.5px;
    margin-bottom: 30.5px;

    border-bottom: 1px solid rgba(78, 154, 165, 0.3);
}

.shop-wrapper .shop-product-wrapper {
    margin-bottom: 42px;
}

/* Shop *Stop* */

.grid-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 58px;
}

.tabs-wrapper {
    position: relative;

    margin: 0 auto;
    margin-bottom: 46px;

    width: fit-content;

    display: flex;
    align-items: center;
}

.tabs-wrapper::before {
    content: "";

    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 5px;
    border-radius: 8px;

    background: rgb(227, 227, 227);
}

.tabs-wrapper>.tab-item:not(:last-child) {
    margin-right: 8px;
}

.tab-item {
    padding: 13px 25px;
    position: relative;

    box-sizing: border-box;
    border: none;
    background-color: transparent;

    color: rgb(0, 0, 0);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0%;
    text-align: left;

    transition: linear .1s;
    cursor: pointer;
}

.tab-item::after {
    content: "";

    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 5px;
    border-radius: 8px;

    transition: ease-in-out .1s;
}

.tab-item.selected::after {
    background: rgb(78, 154, 165);
}

.tab-item:hover {
    opacity: .8;
}
.tab-item:disabled {
    cursor: not-allowed;
    opacity: .5;
}
/* Main section *Start* */

section:first-child {
    margin-top: 60px;
}

section:last-child {
    margin-bottom: 102px;
}

.main-section>.container {
    margin: 0 201.5px;
    padding: 41px 116px 83px 62px;

    border-radius: 40px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background: rgb(255, 255, 255);
}

.main-section__h1 {
    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0%;
    text-align: left;
}

.main-section__p {
    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: left;
}

.main-section .pros-list {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
}

.main-section .pros-list__item {
    padding: 59px 34px 22px 34px;

    width: 100%;
    border-radius: 15px;

    background-position: center 22px;

    text-align: center;

    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1);
    border: none;
}

.main-section .main-section__h1 {
    margin-bottom: 32px;
}

.main-section .main-section__p {
    margin-bottom: 32px;
}

.main-section .primary-btn {
    margin-bottom: 47px;
    width: fit-content;
    border-radius: 15px;
}

.main-section__bg {
    margin-left: 40px;
}

/* Main section *Stop* */


/* Reason section *Start* */

.reasons-section>.container {
    margin: 0 202px;
}

.reasons-section .section-h2 {
    margin-bottom: 65px;
    text-align: center;
}

.reasons-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 56px;
}

.reasons-list__item {
    padding: 45px 40px;

    border-radius: 40px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background: rgb(255, 255, 255);
}

.reasons-list__h3 {
    color: rgb(30, 30, 30);
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0%;
    text-align: left;
}

.reasons-list__p:not(:first-child) {
    margin-top: 8px;
}

.reasons-list__p {
    color: rgb(136, 136, 136);
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0%;
    text-align: left;
}

.reasons-list__icon {
    margin-left: 35px;

    min-width: 108px;
    height: 108px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 15px;

    background: linear-gradient(222.24deg, rgb(204, 246, 252) 0%, rgb(132, 191, 199) 72.764%);
}

/* Reason section *Stop* */

/* Methods section *Start* */

section:not(:last-child) {
    margin-bottom: 105px;
}

.methods-section {}

.methods-section>.container {
    margin: 0 202.5px;
}

.methods-section .section-h2 {
    margin-bottom: 65px;
    text-align: center;
}

.methods-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
}

.methods-list__item {
    padding: 48px 45px;

    display: flex;
    align-items: center;

    box-sizing: border-box;
    border: 1px solid rgb(79, 155, 165);
    border-radius: 25px;

    background: rgb(255, 255, 255);
}

.methods-list__icon {
    margin-right: 30px;

    min-width: 85px;
    height: 85px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgb(245, 245, 246);
    border-radius: 50%;
}

.methods-list__h3 {
    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0%;
    text-align: left;
}

.methods-list__p {
    color: rgb(136, 136, 136);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0%;
    text-align: left;
}

.methods-list__p:not(:first-child) {
    margin-top: 8px;
}

/* Methods section *Stop* */


/* Places section *Start* */

.places-section {}

.places-section>.container {
    margin: 0 217.5px;
}

.countries-list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 27px;
}

.countries-list__item {
    padding: 20px 25px;

    border-radius: 15px;

    background: rgba(85, 160, 171, 0.06);
}

.countries-list__icon {
    margin-bottom: 17px;

    width: 114px;
    height: 70.53px;

    border-radius: 5px;
}

.countries-list__h3 {
    margin-bottom: 8px;

    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0%;
    text-align: left;
}

.countries-list__p {
    color: rgb(136, 136, 136);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0%;
    text-align: left;
}

.places-section .section-h2 {
    margin-bottom: 65px;
    text-align: center;
}

.places-section .primary-btn {
    width: fit-content;
    margin: 65px auto 0 auto;
}

/* Places section *Stop* */

/* All countries section *Start* */

.all-countries-section>.container {
    margin: 0 182px;
}

.panel_bordered {
    padding: 69px 65px;

    box-sizing: border-box;
    border: 2px solid rgb(78, 154, 165);
    border-radius: 10px;

}

.panel_bordered .section-h2 {
    margin-bottom: 44px;
    font-weight: 500;
    text-align: left;
}

.all-countries-section .panel_bordered:not(:last-child) {
    margin-bottom: 65px;
}

.text-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
}

.text-list li {
    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0%;
    text-align: left;
}

/* All countries section *Stop* */

/* Prices section *Start* */

.prices-section>.container {
    margin: 0 263.5px;
}

.prices-section .section-p {
    margin-bottom: 65px;
}

.prices-calculator {
    padding: 34px 0;
    padding-top: 150px;

    border-radius: 25px;

    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    background: rgb(255, 255, 255);
}

.prices-calculator>.container {
    margin: 0 45px;
}

.prices-calculator>.container>.grid-wrapper {
    align-items: flex-end;
    grid-template-columns: 2fr 1fr;
}

.prices-calculator>.container>.grid-wrapper>div>.prices-calculator__panel {
    height: fit-content;

    display: grid;
    grid-template-columns: 1fr auto 1fr;
}

.prices-calculator>.container>.grid-wrapper>.prices-calculator__panel:last-child {
    height: calc(255px - 60px);
    display: flex;
}

.prices-calculator__panel {
    padding: 30px 20px;

    border-radius: 15px;

    background: rgb(212, 234, 237);
}

.prices-calculator__panel>div:first-child {
    width: 100%;
}

.prices-calculator__panel .summary-list__item:not(:last-child) {
    padding-bottom: 14px;
    border-bottom: 1px solid rgb(255, 255, 255);
}

.prices-calculator__panel .primary-btn {
    margin-top: 33px;
}

.prices-calculator__panel .summary-list {
    width: 100%;
}

.prices-calculator__panel .summary-list__name {
    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.32px;
    text-align: left;
}

.prices-calculator__panel .summary-list__value {
    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: 0%;
    text-align: center;
}


.prices-calculator__pros-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 35px;
}

.prices-calculator__pros-list__item {
    padding-left: 63px;
    min-height: 30px;

    display: flex;
    align-items: center;

    background-image: url(../../public/double-checkmarks.svg);
    background-repeat: no-repeat;
    background-position: left center;

    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0%;
    text-align: left;
}

.price-slider+.prices-calculator__panel {
    margin-top: 49px;
}

.price-slider__line {
    position: relative;

    width: 100%;
    height: 8px;
    border-radius: 8px;

    background: rgb(225, 248, 250);
}

.price-slider__line_filled {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    border-radius: 8px;
    background: rgb(78, 154, 165);
}

.price-slider__line_filled>.container {
    position: relative;

    width: 100%;
    height: 100%;
}

.price-slider__spin {
    position: absolute;
    top: 50%;
    right: 0;

    width: 24px;
    height: 24px;

    box-sizing: border-box;
    border: 1px solid rgb(210, 210, 210);
    border-radius: 50%;

    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    background: rgb(255, 255, 255);

    transform: translateY(-50%);

    cursor: pointer;
}

.price-slider__price-text {
    position: absolute;
    left: 50%;
    bottom: calc(100% + 8px);

    padding: 7.5px 15px;
    width: fit-content;
    border-radius: 10px;

    color: rgb(255, 255, 255);
    font-family: Be Vietnam Pro;
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: 0%;
    text-align: center;
    white-space: nowrap;

    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
    background: rgb(78, 154, 165);

    transform: translateX(-50%);
}

.payment-types {
    width: fit-content;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 6px;
}

.payment-types__item {
    padding: 2px 4px;

    display: flex;
    align-items: center;

    border-radius: 5px;

    background: rgb(255, 255, 255);
}

.payment-types__item>img {
    margin-right: 5px;
}

.payment-types__item>span {
    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.32px;
    text-align: left;
}

.prices-calculator__panel .text-h3 {
    margin-bottom: 10px;
}

.prices-calculator__panel>.separator {
    width: 1px;
    height: 100%;

    background-color: rgb(255, 255, 255);
    margin: 0 48px;
}

/* Prices section *Stop* */


/* Functional section *Start* */

.functional-section>.container {
    margin: 0 366px;
}

.functional-section .section-h2 {
    margin-bottom: 65px;
}

.functional-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 51px 43px;
}

.functional-list__item {
    position: relative;

    padding: 31px;
    padding-bottom: 133px;
    border-radius: 25px;

    background: rgb(255, 255, 255);
}

.functional-list__h3 {
    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0%;
    text-align: left;
}

.functional-list__p {
    color: rgb(136, 136, 136);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0%;
    text-align: left;
}

.functional-list__h3+.functional-list__p {
    margin-top: 8px;
}

.functional-list__icon {
    position: absolute;
    right: 31px;
    bottom: 31px;
}

.functional-list__p+.functional-list__icon {
    margin-top: 24px;
}

/* Functional section *Stop* */


/* Solutions section *Start* */

.solutions-section>.container {
    margin: 0 199px;
}

.solutions-section .section-p {
    margin-bottom: 65px;
}

.solutions-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 53px;
}

.solutions-list__item {
    padding: 31px 37px;

    border-radius: 25px;

    background: rgb(212, 234, 237);
}

.solutions-list__icon {
    width: 46px;
    height: 46px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgb(78, 154, 165);
    border-radius: 50%;
}

.solutions-list__icon+.solutions-list__h3 {
    margin-top: 19px;
}

.solutions-list__h3 {
    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0%;
    text-align: left;
}

.solutions-list__p {
    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0%;
    text-align: left;
}

.solutions-list__h3+.solutions-list__p {
    margin-top: 8px;
}

/* Solutions section *Stop* */


/* Cabinet section *Start* */

.cabinet-section>.container {
    margin: 0 200px;
}

.cabinet-section .section-h2 {
    margin-bottom: 75px;
}

.cabinet-section__wrapper {
    display: grid;
    grid-template-columns: .6fr 1fr;
    gap: 28px;
}

.cabinet-section .pros-list__item {
    background-color: transparent;
    font-weight: 600;
    border-radius: 30px;
}

.cabinet-section__p {
    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0%;
    text-align: left;
}

.cabinet-section__p+.pros-list {
    margin-top: 24px;
}

.cabinet-section__preview {
    height: fit-content;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 30px;
    background: rgb(217, 217, 217);

    overflow: hidden;
}

.cabinet-section__preview>img {
    width: 100%;
    border-radius: 30px;
}

.cabinet-section .primary-btn {
    padding-left: 78px;
    padding-right: 78px;
    width: fit-content;
}

.cabinet-section .pros-list__item {
    padding-left: 70px;
    background-size: 27px 34px;
}

/* Cabinet section *Stop* */



/* Whitelist page *Start* */

.form-section__header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.form-section__header:not(:last-child) {
    margin-bottom: 12px;
}

.whitelist-wrapper {
    min-height: 850px;

    display: grid;
    grid-template-columns: 1fr auto;
    gap: 31px;
}

.whitelist-wrapper .form:last-child {
    display: flex;
    flex-direction: column;
}

.whitelist-wrapper .textarea {
    height: 100%;
}

.whitelist-wrapper>.form:first-child {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.whitelist-wrapper>.form:first-child .primary-btn {
    width: fit-content;
}

.whitelist-wrapper .table_fade tr td:last-child {
    vertical-align: middle;
}

.whitelist-wrapper .table_fade td:last-child>img {
    height: 32px;

    cursor: pointer;
}

.whitelist-wrapper .table_fade tbody {
    max-height: 600px;

    overflow-y: auto;
}

.form .text-h2 {
    margin-bottom: 30px;
}

.form-section:not(:last-child) {
    margin-bottom: 30px;
}

.input-wrapper_fade {
    position: relative;

    padding: 13px 21px;

    box-sizing: border-box;
    border: 1px solid rgba(78, 154, 165, 0.5);
    border-radius: 8px;

    box-shadow: 0px 0px 8px 0px rgba(78, 154, 165, 0.5);
    background: rgb(255, 255, 255);
}

.input-error {
    padding: 10px 0;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.input-error__icon {
    width: 24px;
    height: 24px;
}
.input-error__icon img {
    width: 100%;
    height: 100%;
}
.input-error__icon:not(:last-child) {
    margin-right: 12px;
}
.input-error__text {
    color: #ff4d4d;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: left;
}

/* Whitelist page *Stop* */

/* Ip skipping page *Start* */

.ipskipping-wrapper {
    min-height: 850px;

    display: grid;
    grid-template-columns: 1fr auto;
    gap: 31px;
}

.ipskipping-wrapper> :last-child {
    height: fit-content;
}

/* Ip skipping page *Stop* */

/* Graph *Start* */

.form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form-header>div {
    display: flex;
    align-items: center;
}

.form-header>div .select-wrapper {
    margin-right: 12px;
    margin-bottom: 0;
}

.form-header>div .select__header {
    min-width: 100px;
}

.graph-tabs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.graph-tabs__item {
    color: rgb(133, 133, 133);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: left;

    cursor: pointer;
}

.graph-tabs__item:not(:last-child) {
    margin-right: 20px;
}

.graph-tabs__item.selected {
    color: rgb(78, 154, 165);
    text-decoration-line: underline;
}

.date-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.date-wrapper>input:first-child {
    display: flex;
    align-items: center;
}

.date-wrapper>input:first-child::after {
    content: "-";
    margin: 0 10px;
}

.form-header .text-h2 {
    margin: 0 40px 0 0;
}

/* Graph *Stop* */


/* M-menu *Start* */

.burger-btn {
    position: relative;
    min-width: 28px;
    height: 18px;

    cursor: pointer;
}

.burger-btn>span {
    position: absolute;
    left: 0;

    display: block;

    width: 100%;
    height: 2px;

    background-color: #000;

    transition: linear .2s;
}

.burger-btn>span:nth-child(1) {
    top: 0;
}

.burger-btn>span:nth-child(2) {
    top: 50%;

    transform: translateY(calc(-50% + 1px));
}

.burger-btn>span:nth-child(3) {
    top: 100%;
}

.burger-btn.opened>span {
    top: 50%;
}

.burger-btn.opened>span:nth-child(1) {
    transform: rotate(45deg);
}

.burger-btn.opened>span:nth-child(2) {
    display: none;
}

.burger-btn.opened>span:nth-child(3) {
    transform: rotate(-45deg);
}

.m-menu {
    display: none;
    max-width: 320px;

    position: fixed;

    top: 0;
    right: 0;

    width: 100vw;
    height: 100dvh;

    background-color: #fff;
    border-left: 1px solid lightgray;
    box-shadow: 60px -16px #000;

    animation: slideOutFromRight ease-in-out .2s;
    z-index: 5;
}

.m-menu.opened {
    display: block;
}

.m-menu::before {
    content: "";

    position: fixed;

    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3px);
    background: rgba(255, 255, 255, 0.2);

    z-index: -1;
}

.m-menu>.container {
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-rows: auto 1fr auto;

    border-left: 1px solid rgb(79, 155, 165);
}

.m-menu__header {
    position: relative;

    padding: 20px;
    border-bottom: 1px solid rgb(79, 155, 165);

    display: flex;
    align-items: center;

    cursor: pointer;
}

.m-menu__title {
    width: 100%;

    color: #000;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0%;
    text-align: center;
}

.m-menu__arrow {
    position: absolute;
    top: 50%;
    left: 20px;

    width: 25px;

    transform: translateY(-50%);
}

.m-menu__content {
    overflow-y: auto;
}

.m-menu__logo {
    width: fit-content;
    margin: 0 auto;
}

.m-nav {
    display: flex;
    flex-direction: column;
}

.m-nav__item {
    padding: 10px 20px;

    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: left;
    text-decoration: none;
}

.m-menu .language-switcher__header {
    padding: 10px 20px;

    color: rgb(30, 30, 30);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: left;
    text-decoration: none;
}

.m-menu .language-switcher__arrow {
    margin-left: auto;
}

.m-menu .language-switcher .sub-menu {
    width: 100%;
    top: 100%;
}

.m-menu .language-switcher.opened .language-switcher__header {
    border-bottom: 1px solid lightgray;
}

.m-nav__item:not(:last-child) {
    border-bottom: 1px solid lightgray;
}

.m-menu__footer {
    padding: 20px;
    border-top: 1px solid rgb(79, 155, 165);
}




/* Newsletter disable page *Start* */

/* Основные стили для страницы */
.newsletter-disable-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
}

/* Заголовок страницы */
.newsletter-disable-page h1 {
    font-size: 24px;
    color: #d9534f;
    text-align: center;
    margin-bottom: 20px;
}

/* Информационный текст */
.newsletter-disable-page p {
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center;
}

/* Стиль кнопки отписки */
.newsletter-disable-page .btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #d9534f;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    margin: 0 auto;
    display: block;

    transition: linear .1s;
}

/* Стили для кнопки при наведении */
.newsletter-disable-page .btn:hover {
    background-color: #c9302c;
}

/* Сообщение об активной/неактивной рассылке */
.newsletter-disable-page .info-message {
    font-size: 16px;
    color: #5bc0de;
    text-align: center;
    margin-bottom: 20px;
}

.newsletter-disable-page .inactive-message {
    color: #5cb85c;
}


/* Newsletter disable page *Stop* */


.rc-slider {
    position: relative;
}

.rc-slider {
    position: relative;
    width: 100%;
    height: 14px;
    padding: 5px 0;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-rail {
    width: 100%;
}

.rc-slider * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-rail {
    position: absolute;
    width: 100%;
    height: 8px;
    background: rgb(225, 248, 250);
    border-radius: 6px;
}

.rc-slider-track {}

.rc-slider * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-track,
.rc-slider-tracks {
    position: absolute;
    height: 8px;
    background: rgb(78, 154, 165);
    border-radius: 6px 0 0 6px;
}

.rc-slider * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
}

.rc-slider * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-handle {
    position: absolute;
    z-index: 1;
    width: 24px;
    height: 24px;
    top: 50%;
    transform: translateY(-50%) !important;
    background-color: #fff;
    border: 1px solid rgb(210, 210, 210);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    cursor: pointer;
    cursor: grab;
    opacity: 0.8;
    -webkit-user-select: none;
    user-select: none;
    touch-action: pan-x;
}

.rc-slider:not(:last-child) {
    margin-bottom: 57px;
}

.rc-tooltip.rc-tooltip-zoom-appear,
.rc-tooltip.rc-tooltip-zoom-enter {
    opacity: 0;
}

.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
    display: block;
}

.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
    opacity: 0;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
    animation-play-state: paused;
}

.rc-tooltip-zoom-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
    animation-play-state: paused;
}

.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
    animation-name: rcToolTipZoomIn;
    animation-play-state: running;
}

.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
    animation-name: rcToolTipZoomOut;
    animation-play-state: running;
}


.traffic-left {
    width: 100%;

    color: rgb(78, 154, 165);
    font-family: Inter;
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0%;
    text-align: center;
}

@keyframes rcToolTipZoomIn {
    0% {
        opacity: 0;
        transform-origin: 50% 50%;
        transform: scale(0, 0);
    }

    100% {
        opacity: 1;
        transform-origin: 50% 50%;
        transform: scale(1, 1);
    }
}

@keyframes rcToolTipZoomOut {
    0% {
        opacity: 1;
        transform-origin: 50% 50%;
        transform: scale(1, 1);
    }

    100% {
        opacity: 0;
        transform-origin: 50% 50%;
        transform: scale(0, 0);
    }
}

.rc-tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    visibility: visible;
    font-size: 12px;
    line-height: 1.5;
    opacity: 0.9;
}

.rc-tooltip-hidden {
    display: block;
}

.rc-tooltip-placement-top,
.rc-tooltip-placement-topLeft,
.rc-tooltip-placement-topRight {
    padding: 5px 0 9px 0;
}

.rc-tooltip-placement-right,
.rc-tooltip-placement-rightTop,
.rc-tooltip-placement-rightBottom {
    padding: 0 5px 0 9px;
}

.rc-tooltip-placement-bottom,
.rc-tooltip-placement-bottomLeft,
.rc-tooltip-placement-bottomRight {
    padding: 9px 0 5px 0;
}

.rc-tooltip-placement-left,
.rc-tooltip-placement-leftTop,
.rc-tooltip-placement-leftBottom {
    padding: 0 9px 0 5px;
}

.rc-tooltip-inner {
    padding: 7.5px 15px;
    color: #fff;
    text-align: left;
    text-decoration: none;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
    background: rgb(78, 154, 165);
    border-radius: 6px;
    min-height: 34px;

    color: rgb(255, 255, 255);
    font-family: Be Vietnam Pro;
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: 0%;
    text-align: center;
    white-space: nowrap;

    transition: linear .2s;
}

.rc-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    bottom: 4px;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: #373737;
}

.rc-tooltip-placement-top .rc-tooltip-arrow {
    left: 50%;
}

.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
    left: 15%;
}

.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    right: 15%;
}

.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    left: 4px;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #373737;
}

.rc-tooltip-placement-right .rc-tooltip-arrow {
    top: 50%;
}

.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
    top: 15%;
    margin-top: 0;
}

.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    bottom: 15%;
}

.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    right: 4px;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: #373737;
}

.rc-tooltip-placement-left .rc-tooltip-arrow {
    top: 50%;
}

.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
    top: 15%;
    margin-top: 0;
}

.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    bottom: 15%;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    top: 4px;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #373737;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
    left: 50%;
}

.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
    left: 15%;
}

.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    right: 15%;
}





@media (max-width: 1620px) {
    .places-section>.container {
        margin: 0 20px;
    }

    .all-countries-section>.container {
        margin: 0 20px;
    }

    .prices-section>.container {
        margin: 0 80px;
    }

    .functional-section>.container {
        margin: 0 80px;
    }

    .solutions-section>.container {
        margin: 0 80px;
    }

    .cabinet-section>.container {
        margin: 0 80px;
    }

    .main-section>.container {
        margin: 0 80px;
    }

    .reasons-section>.container {
        margin: 0 80px;
    }

    .methods-section>.container {
        margin: 0 80px;
    }

    .banner-item {
        background-size: 100px auto;
        background-position: 90% calc(100% - 30px);
    }
}

@media (max-width: 1440px) {
    .payment-types {
        grid-template-columns: repeat(3, 1fr);
    }


    .places-section>.container {
        margin: 0 20px;
    }

    .all-countries-section>.container {
        margin: 0 20px;
    }

    .prices-section>.container {
        margin: 0 20px;
    }

    .functional-section>.container {
        margin: 0 20px;
    }

    .solutions-section>.container {
        margin: 0 20px;
    }

    .cabinet-section>.container {
        margin: 0 20px;
    }

    .main-section>.container {
        margin: 0 20px;
    }

    .reasons-section>.container {
        margin: 0 20px;
    }

    .methods-section>.container {
        margin: 0 20px;
    }

    .banner-item {
        background-size: 75px auto;
    }

    .proxylist-wrapper>.grid-wrapper {
        grid-template-columns: 1fr;
    }

    .proxylist-wrapper .form-header {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .proxylist-wrapper .form-header> :not(:last-child) {
        margin-bottom: 20px;
    }
}

@media (max-width: 1230px) {
    .countries-list {
        grid-template-columns: repeat(5, 1fr);
    }

    .prices-calculator>.container>.grid-wrapper>div>.prices-calculator__panel {
        height: fit-content;

        display: grid;
        grid-template-columns: 1fr;
        gap: 12px;
    }

    .payment-types {
        display: flex;
        flex-wrap: wrap;
    }

    .prices-calculator>.container>.grid-wrapper {
        grid-template-columns: 1fr;
    }

    .reasons-list {
        grid-template-columns: repeat(2, 1fr);
    }

    .methods-list {
        grid-template-columns: repeat(2, 1fr);
    }

    .main-section__bg {
        width: 300px;
    }

    .banner-list {
        grid-template-columns: 1fr;
    }

    .tabs-wrapper {
        flex-direction: column;
    }
}

@media (max-width: 1040px) {
    .countries-list {
        grid-template-columns: repeat(4, 1fr);
    }

    .functional-list {
        grid-template-columns: repeat(2, 1fr);
    }

    .solutions-list {
        grid-template-columns: repeat(2, 1fr);
    }

    .cabinet-section__wrapper {
        grid-template-columns: 1fr;
    }

    .reasons-list__item {
        padding: 25px 20px;
    }

    .reasons-list__icon {
        min-width: 76px;
        height: 76px;
    }

    .reasons-list__icon>img {
        width: 40px;
    }

    .main-section>.container {
        padding: 41px 52px;
    }

    .main-section__bg {
        display: none;
    }

    .profile-main {
        padding: 16px 0;
        min-height: calc(100% - 32px);
    }

    .profile-main>.container {
        margin: 0 16px;
    }

    .table th {
        padding: 18px 28px;
    }

    .balance-deposit-form {
        max-width: unset;
        padding: 24px 0;
    }

    .balance-deposit-form>.container {
        margin: 0 32px;
    }

    .deposit-history {
        padding: 24px 0;
    }

    .deposit-history>.container {
        margin: 0 32px;
    }

    .shop-wrapper {
        grid-template-columns: 1fr;
    }

    .whitelist-wrapper {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 850px) {
    .countries-list {
        grid-template-columns: repeat(3, 1fr);
    }
    .popup .buttons-wrapper {
        grid-template-columns: 1fr;
        gap: 12px;
    }
    .text-list {
        grid-template-columns: repeat(3, 1fr);
    }

    .methods-list {
        grid-template-columns: 1fr;
    }

    .reasons-list {
        grid-template-columns: 1fr;
    }

    .auth-main {
        margin: 10px 15px;
        max-width: unset;
        grid-template-columns: 1fr;
    }

    .auth-main__side {
        padding: 60px 0;
    }

    .auth-main__side:first-child {
        border-radius: 40px 40px 0 0;
    }

    .auth-h1 {
        font-size: 28px;
        line-height: 32px;
    }

    .auth-p {
        font-size: 18px;
        line-height: 22px;
    }

    .banner-item {
        padding: 24px 0;
    }

    .banner-item>.container {
        margin: 0 26px;
    }

    .banner-item .text-h2 {
        margin-bottom: 16px;
    }

    .pros-list__item {
        padding: 14px 24px 14px 60px;

        font-size: 14px;
        line-height: 16px;

        background-position: 20px center;
        background-size: 22px;
    }

    .proxylist-wrapper .form .grid-wrapper {
        grid-template-columns: 1fr;
    }

    .form-header>div {
        width: 100%;
        align-items: flex-start;
        flex-direction: column;
    }

    .form-header>div>:not(:last-child) {
        margin-bottom: 20px;
    }

    .ipskipping-wrapper {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 650px) {
    .tabs-wrapper {
        width: 100%;

        flex-direction: column;
    }

    .tab-item {
        width: 100%;
        text-align: center;
    }

    .proxylist-wrapper .form .grid-wrapper {
        gap: 20px;
    }

    .tabs-wrapper>.tab-item:not(:last-child) {
        margin-right: 0px;
        margin-bottom: 12px;
    }

    .panel_bordered {
        padding: 25px 25px;
    }

    .form {
        padding: 25px 25px;
    }

    .countries-list {
        grid-template-columns: repeat(2, 1fr);
    }

    .text-list {
        grid-template-columns: repeat(2, 1fr);
    }

    .panel_bordered .section-h2 {
        margin-bottom: 18px;
    }

    .section-h2 {
        font-size: 24px;
        line-height: 28px;
    }

    .balance-deposit-form-wrapper {
        grid-template-columns: 1fr;
    }

    .deposit-variants__icons {
        display: none;
    }

    .places-section .section-h2 {
        margin-bottom: 35px;
        text-align: center;
    }

    section:first-child {
        margin-top: 30px;
    }

    .functional-list {
        grid-template-columns: 1fr;
    }

    .solutions-list {
        grid-template-columns: 1fr;
    }

    .main-section .pros-list {
        grid-template-columns: 1fr;
    }

    .section-h2+.section-p {
        margin-top: 9px;
    }

    .section-p {
        font-size: 16px;
        line-height: 22px;
    }

    .prices-section .section-p {
        margin-bottom: 35px;
    }

    .prices-calculator {
        padding: 15px 0;
        padding-top: 65px;
    }

    .prices-calculator>.container {
        margin: 0 15px;
    }

    .prices-calculator__panel .summary-list__value {
        font-size: 24px;
        line-height: 24px;
    }

    .cabinet-section .section-h2 {
        margin-bottom: 35px;
    }

    .cabinet-section__p {
        font-size: 18px;
        line-height: 22px;
    }

    .cabinet-section .pros-list__item {
        padding-left: 49px;
        background-size: 19px 23px;
        background-position: 18px center;
    }


    .rc-tooltip-inner {
        padding: 4.5px 7.5px;
        color: #fff;
        text-align: left;
        text-decoration: none;
        box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
        background: rgb(78, 154, 165);
        border-radius: 6px;
        min-height: 34px;

        color: rgb(255, 255, 255);
        font-family: Be Vietnam Pro;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0%;
        text-align: center;
        white-space: nowrap;
    }

    .rc-slider:not(:last-child) {
        margin-bottom: 35px;
    }



    .deposit-history>.container {
        margin: 0 16px;
    }

    .table {
        border: 0;
    }

    .table thead {
        display: none;
    }

    .table tr {
        margin-bottom: 10px;
        display: block;
        border-bottom: 2px solid #ddd;
    }

    .table tr td:first-child::before {
        position: relative;
        content: attr(data-label);
        background-color: transparent !important;
    }

    .table tr.success td:first-child {
        background-color: #61C027;
    }

    .table tr.error td:first-child {
        background-color: #F12E2E;
    }

    .table tr td:first-child {
        border-radius: 8px 8px 0 0;

        background-color: #4E9AA5;
        color: #fff;
    }

    .table td {
        display: block;
        text-align: right;
        font-size: 13px;
        border-bottom: 1px dotted #ccc;
        border-right: 1px solid transparent;
    }

    .table td {
        padding: 9px 12px;

        text-align: right;
    }

    .table td:last-child {
        border-bottom: 0;
    }

    .table td:before {
        content: attr(data-label);
        float: left;
        text-transform: uppercase;
        font-weight: bold;
    }

    .table_fade thead {
        display: none;
    }

    .table_fade td {
        padding: 9px 12px;
        width: unset !important;

        display: block;
        text-align: right;
        font-size: 13px;
        border-bottom: 1px dotted #ccc;
        border-right: 1px solid transparent;
    }

    td .flex-wrapper {
        align-items: flex-end;
        flex-direction: column;
    }

    .table_fade td:before {
        content: attr(data-label);
        float: left;
        text-transform: uppercase;
        font-weight: bold;
    }

    .form-section__content>.grid-wrapper {
        grid-template-columns: 1fr;
        gap: 12px;
    }

    .text-h2 {
        font-size: 20px;
        line-height: 24px;
    }

    .balance-deposit-form .text-h2 {
        margin-bottom: 16px;
    }

    .deposit-variants__icon {
        margin-right: 10px;
        width: 25px;
    }

    .deposit-variants__item {
        padding: 8.5px 22px;
    }

    .deposit-variants__title {
        font-size: 16px;
        line-height: 18px;
    }

    .deposit-variants__item .checkbox {
        margin-right: 14px;
    }

    .banner-item {
        border-radius: 12px;
        background-image: none !important;
    }

    .shop-product-wrapper {
        padding: 25px 30px;
    }

    .shop-payment-form {
        padding: 25px 30px;
    }

    .summary {
        padding: 25px 30px;
    }

    .change-data-form {
        padding: 25px 30px;
    }

    .label {
        font-size: 16px;
        line-height: 20px;
    }

    .label:not(:last-child) {
        display: block;
        margin-bottom: 8px;
    }

    .form .input-wrapper_fade:not(:last-child) {
        margin-bottom: 12px;
    }

    .traffic-left {
        font-size: 24px;
        line-height: 24px;
    }
}

@media (max-width: 450px) {
    .countries-list {
        grid-template-columns: repeat(1, 1fr);
    }

    .text-list {
        grid-template-columns: repeat(1, 1fr);
    }

    .main-section>.container {
        padding: 20px 30px;
    }

    .main-section__h1 {
        font-size: 26px;
        line-height: 30px;
    }

    section:not(:last-child) {
        margin-bottom: 45px;
    }

    .reasons-list {
        gap: 25px;
    }

    .reasons-section .section-h2 {
        margin-bottom: 45px;
    }

    .reasons-list__item {
        border-radius: 12px;
    }

    .reasons-list__icon {
        min-width: 56px;
        height: 56px;
    }

    .reasons-list__icon>img {
        width: 30px;
    }

    .reasons-list__h3 {
        font-size: 18px;
        line-height: 20px;
    }

    .reasons-list__p {
        font-size: 14px;
        line-height: 130%;
    }

    .methods-list__item {
        padding: 15px 25px;
    }

    .methods-list__icon {
        min-width: 65px;
        height: 65px;
    }

    .methods-list__icon>img {
        width: 35px;
    }

    .methods-list__h3 {
        font-size: 18px;
        line-height: 20px;
    }

    .methods-list__p {
        font-size: 14px;
        line-height: 130%;
    }

    .pros-list__item {
        padding: 10px 12px;

        font-size: 14px;
        line-height: 18px;
    }

    .prices-calculator__pros-list__item {
        background-size: 24px 34px;
    }

    .prices-calculator__pros-list__item {}

    .primary-btn {
        padding: 8px 16px;

        font-size: 18px;
        line-height: 22px;
    }

    .cancel-btn {
        padding: 8px 16px;

        font-size: 18px;
        line-height: 22px;
    }

    .prices-calculator>.container>.grid-wrapper>.prices-calculator__panel:last-child {
        height: fit-content;
    }

    .prices-calculator__pros-list {
        gap: 8px;
    }

    .prices-calculator__pros-list__item {
        padding-left: 45px;

        font-size: 14px;
    }

    .cabinet-section .primary-btn {
        padding-left: 0;
        padding-right: 0;

        width: 100%;
    }

    .section-h2 {
        font-size: 22px;
    }

    .auth-main__side {
        padding: 25px 0;
    }

    .input-wrapper {
        padding: 15px 20px;
        border-radius: 20px;
    }

    .auth-link {
        font-size: 14px;
        line-height: 18px;
    }

    .auth-social-list__item {
        height: 32px;
        width: 32px;
    }

    .auth-social-list__item>img {
        width: 100%;
        height: 100%;
    }

    .balance-deposit-form__footer {
        grid-template-columns: 1fr;
    }

    .pros-list__item {
        padding-left: 56px;
    }

    .text-h2 {
        font-size: 18px;
        line-height: 22px;
    }

    .summary-list__name {
        font-size: 16px;
        line-height: 20px;
    }

    .summary-list__value {
        font-size: 16px;
        line-height: 20px;
    }

    .summary-content {
        padding: 12px 0;
    }

    .summary-footer {
        padding-top: 16px;
    }

    .summary-footer .summary-list {
        margin-bottom: 16px;
    }

    .summary-list__value.bold {
        font-size: 22px;
        line-height: 24px;
    }

    .choose-list__item {
        padding: 12px 20px;
    }

    .choose-list__icon {
        width: 22px;
        height: 22px;

        margin-right: 16px;
    }

    .choose-list__title {
        font-size: 14px;
        line-height: 16px;
    }

    .shop-payment-form__header {
        padding-bottom: 12.5px;
        margin-bottom: 15.5px;
    }

    .choose-list {
        gap: 16px;
    }

    .summary-footer .primary-btn {
        font-size: 18px;
        line-height: 22px;
    }

    .date-wrapper {
        width: 100%;
        flex-direction: column;
    }

    .date-wrapper>input:first-child::after {
        content: "";
        margin: 0;
    }

    .form-header>div .select-wrapper {
        width: 100%;
    }

    .date-wrapper>input:first-child {
        display: block;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #4E9AA5;
    }

    .textarea {
        min-height: 300px;
        padding: 6px 12px;
    }
}

@media (max-width: 400px) {
    .m-menu {
        max-width: 400px;
    }

    .reasons-list__icon {
        display: none;
    }

    .methods-list__icon {
        display: none;
    }

    .countries-list__icon {
        width: 65px;
        height: auto;
    }

    .countries-list__h3 {
        margin-bottom: 4px;

        font-size: 18px;
        line-height: 20px;
    }

    .countries-list__p {
        font-size: 14px;
    }

    .shop-product-wrapper {
        padding: 16px 15px;
    }

    .shop-product-wrapper .text-h2 {
        margin-bottom: 12px;

        font-size: 18px;
        line-height: 20px;
    }

    .shop-payment-form {
        padding: 16px 15px;
    }

    .select__title {
        max-width: calc(100vw - 144px);
    }
}

/* M-menu *Stop* */


/* Animations *Start* */

@keyframes slideOutFromRight {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0%);
    }
}

/* Animations *Stop* */

.not-found-page {
    display: flex;
    align-items: center;

    width: 100%;
    height: calc(100% - 24px);
}

.not-found-page>.container {
    width: 518px;
    margin: 0 auto;

    background-image: url(../../public/no-found.svg);
    background-size: 330px 352px;
    background-position: right top;
    background-repeat: no-repeat;
}

.not-found-wrapper {
    position: relative;

    padding: 5px 10px;
    width: fit-content;
    border: 10px solid #58a5b0;
    border-radius: 8px;

    backdrop-filter: blur(4px);
    background: linear-gradient(135.00deg, rgba(78, 154, 165, 0.74) 8.903%, rgba(203, 248, 255, 0.74) 91.298%);
}

.not-found-page .not-found-wrapper {
    margin-top: 75px;
}

.not-found-wrapper h1 {
    color: rgb(0, 0, 0);
    font-family: Inter;
    font-size: 96px;
    font-weight: 700;
    line-height: 116px;
    letter-spacing: 0%;
    text-align: center;
}

.not-found-wrapper p {
    color: rgb(0, 0, 0);
    font-family: Inter;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0%;
    text-align: left;
}

.not-found-link {
    padding: 12px 24px;
    margin: 0 auto;

    width: fit-content;
    display: block;

    border-radius: 8px;
    background: rgb(78, 154, 165);

    color: rgb(255, 255, 255);
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0%;
    text-align: center;
    text-decoration: none;
}

.not-found-page .not-found-link {
    margin-top: 115px;
}

/* Адаптация для планшетов (экран шире 768px, но меньше 1024px) */
@media (max-width: 1024px) {
    .not-found-page>.container {
        width: 400px;
        background-size: 250px 280px;
    }

    .not-found-wrapper h1 {
        font-size: 72px;
        line-height: 90px;
    }

    .not-found-wrapper p {
        font-size: 20px;
        line-height: 25px;
    }

    .not-found-link {
        font-size: 18px;
    }
    .page-section:not(:last-child) {
        margin-bottom: 12px;
    }
    .partnership-page  .page-section {
        grid-column: span 12!important;
    }
}

/* Адаптация для мобильных устройств (экран шире 480px, но меньше 768px) */
@media (max-width: 768px) {
    .not-found-page>.container {
        width: 450px;
        background-size: 200px 220px;
        background-position: right center;
    }

    .not-found-wrapper {
        padding: 5px 8px;
        border-width: 8px;
    }

    .not-found-wrapper h1 {
        font-size: 60px;
        line-height: 70px;
    }

    .not-found-wrapper p {
        font-size: 18px;
        line-height: 22px;
    }

    .not-found-link {
        padding: 10px 20px;
        font-size: 16px;
    }
}

/* Адаптация для маленьких мобильных устройств (экран меньше 480px) */
@media (max-width: 480px) {
    .not-found-page {
        flex-direction: column;
        justify-content: flex-start;
    }

    .not-found-page>.container {
        width: 100%;
        background-position: center top;
        background-size: 135px auto;
        margin: 0 20px;
    }

    .not-found-wrapper {
        margin: 0 auto;

        padding: 5px 5px;
        border-width: 6px;
    }

    .not-found-page .not-found-wrapper {
        margin-top: 150px;
    }

    .not-found-wrapper h1 {
        font-size: 48px;
        line-height: 58px;
    }

    .not-found-wrapper p {
        font-size: 16px;
        line-height: 20px;
    }

    .not-found-link {
        padding: 8px 16px;
        font-size: 14px;
    }

    .not-found-page .not-found-link {
        margin-top: 35px;
        margin-bottom: 35px;
    }
}

/* Terms and conditions *Start* */

.graph-tooltip {
    background: rgba(255, 255, 255, 0.9);
    /* Темный фон с небольшой прозрачностью */
    color: #000;
    /* Белый текст */
    padding: 10px 15px;
    /* Внутренние отступы */
    border-radius: 8px;
    /* Скругленные углы */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Тень для придания объема */
    font-size: 15px;
    /* Размер текста */
}

.graph-tooltip__value {
    /* Жирный шрифт для значения */
    margin-bottom: 4px;
    /* Отступ между значением и именем */
}

.graph-tooltip__value .green {
    color: #4caf50;
    /* Зеленый цвет для единиц измерения */
    font-weight: normal;
    /* Обычный вес текста */
}

.graph-tooltip__name {
    font-size: 12px;
    /* Более мелкий текст для имени */
    color: #464646;
    /* Серый цвет для описания */
}

/* Основные стили страницы */
.info-page {
    background-color: #f9f9f9;
    padding: 40px 0;
    font-family: 'Arial', sans-serif;
}

.info-container {
    max-width: 1000px;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Заголовок страницы */
.info-title {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

/* Введение */
.info-intro {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 30px;
    line-height: 1.6;
}

/* Секции */
.info-section {
    margin-bottom: 30px;
}

/* Заголовки секций */
.info-section-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 5px;
}

/* Описание секций */
.info-section-description {
    font-size: 1.1em;
    color: #555;
    margin-bottom: 15px;
    line-height: 1.6;
}

/* Список правил */
.info-list {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 15px;
}

.info-list li {
    font-size: 1em;
    color: #555;
    margin-bottom: 10px;
    line-height: 1.6;
}

/* Медиа-запрос для мобильных устройств */
@media (max-width: 600px) {
    .info-container {
        padding: 15px;
    }

    .info-title {
        font-size: 1.8em;
    }

    .info-section-title {
        font-size: 1.4em;
    }
}


/* Terms and conditions *Stop* */