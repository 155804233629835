.dropdown-button {
    position: relative;
    display: inline-block;
}

.dropdown-button button {
    width: 100%;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    box-sizing: border-box;
    border: 1px solid rgba(78, 154, 165, 0.5);
    border-top: none;
    border-radius: 0 0 8px 8px;
    box-shadow: 0px 0px 8px 0px rgba(78, 154, 165, 0.5);
    background-color: #fff;

    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;

    z-index: 2;
}
.opened button {
    border-radius: 15px 15px 0 0;
}
.dropdown-menu li {
    padding: 12px 21px;

    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: left;

    cursor: pointer;
    transition: ease-in-out .1s;
}

.dropdown-menu li:hover {
    background: rgb(244, 244, 244);
}