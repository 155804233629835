.language-switcher {
    position: relative;
}
.language-switcher__header {
    display: flex;
    align-items: center;

    cursor: pointer;
}
.language-switcher__header > img {
    margin-right: 16px;
}
.language-switcher__icon {
    margin-right: 14px;

    width: 48px;
    height: 48px;

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.15);
    background: rgb(255, 255, 255);
    border-radius: 50%;
}

.language-switcher__title {
    color: rgb(0, 0, 0);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: left;
}

.language-switcher__arrow {
    margin-left: 14px;
    margin-right: 0!important;
}

.language-switcher .sub-menu {
    width: calc(100%);
    display: none;

    position: absolute;

    top: calc(100% + 12px);
    left: 0;

    background-color: #fff;
    border-radius: 8px;
}

.language-switcher .sub-list {
    padding: 6px 0;

    display: grid;
    grid-template-columns: 1fr;
}

.language-switcher .sub-list__item {
    padding: 6px 12px;

    color: rgb(0, 0, 0);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: left;
    text-decoration: none;

    transition: ease-in-out .1s;
    cursor: pointer;
}

.language-switcher .sub-list__item:hover {
    background-color: rgb(78, 154, 165);
    color: #fff;
}

.language-switcher .sub-list__item:not(:last-child) {
    border-bottom: 1px solid lightgray;
}

.language-switcher.opened .sub-menu {
    display: block;
}

.language-switcher.has-sub .language-switcher__header {
    user-select: none;
    cursor: pointer;
}