@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-VariableFont_slnt,wght.ttf') format('truetype');
}  



@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
