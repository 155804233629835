.landing-footer {
    padding: 29px 0;

    background: rgb(242, 242, 242);
}

.landing-footer>.container {
    margin: 0 196px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}
.landing-footer__logo {
    margin-right: 60px;
}
.landing-footer-nav {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.landing-footer-nav__item {
    color: rgb(30, 30, 30);
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0%;
    text-align: center;
    text-decoration: none;

    transition: linear .1s;
}

.landing-footer-nav__item:hover {
    color: rgb(78, 154, 165);
}
.landing-footer-nav__item:not(:last-child) {
    margin-right: 40px;
}
@media (max-width: 1620px) {
    .landing-footer>.container {
        margin: 0 80px;
    }
}
@media (max-width: 1440px) {
    .landing-footer>.container {
        margin: 0 20px;
    }
}
@media (max-width: 800px) {
    .landing-footer {
        padding: 20px 0;
    }
    .landing-footer>.container {
        flex-direction: column;
        align-items: flex-start;
    }
    .landing-footer__logo {
        margin-right: 0;
        margin-bottom: 20px;
    }
    .landing-footer-nav__item:not(:last-child) {
        margin-right: 20px;
    }
    .landing-footer-nav__item {
        font-size: 16px;
    }
}