.input-wrapper__buttons {
    position: absolute;

    display: flex;
    flex-direction: column;
    align-items: center;

    top: 50%;
    right: 10px;

    transform: translateY(-50%);
}
.input-wrapper__btn {
    width: 16px;
    height: 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 10px;

    border: none;
    background-color: transparent;

    cursor: pointer;
}