.pagination-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
}

.pagination-container h2 {
    margin-bottom: 10px;
    font-family: 'Inter';
    font-size: 24px;
}

.pagination-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 600px;
}

.pagination-list li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
}
.pagination-controls span {
    font-family: 'Inter';
}
.pagination-controls button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #4E9AA5;
    font-family: 'Inter';
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pagination-controls button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination-controls button:hover:not(:disabled) {
    background-color: #2c666e;
}

.pagination-meta {
    margin-top: 10px;
    font-family: 'Inter';
    font-size: 14px;
    color: #555;
}


@media (max-width: 650px) {
    .pagination-controls {
        flex-direction: column;
    }
    .pagination-controls > :not(:last-child) {
        margin-bottom: 12px;
    }
}